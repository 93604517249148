<template>
  <div>
    <b-card class="mb-2">
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list()" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="마스터 ID, 디자이너 SKU, 마스터 상품명을 넣어주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list()"
                      v-focus></b-form-input>
        <!--<b-input-group-append>
          <b-button variant="light" v-b-toggle.collapse1>검색조건 <i class="fa fa-chevron-down"></i></b-button>
        </b-input-group-append>-->
      </b-input-group>

      <form-options v-model="form.list" v-bind="{formOptions}"></form-options>

      <b-collapse id="collapse1" v-model="collapse.detail">
        <form-options class="mb-2" v-model="form.list" v-bind="{formOptions: formOptionsDetail}">
          <template v-slot:slot1>
            <div class="flex-grow-0 mb-1 mr-2">
              <small>Color 선택</small><br/>
              <color-checkbox v-model="form.list.color"></color-checkbox>
            </div>
          </template>
          <template v-slot:slotEnd>
            <div class="flex-grow-0 mb-1 mr-2">
              <small>기본 상품수</small><br/>
              <b-form-input class="text-center w-70px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.list.limit" @keypress.enter="list()"></b-form-input>
            </div>
          </template>
        </form-options>

        <b-row class="mb-2">
          <b-col cols="12" lg="6">
            <brand-preset class="" v-model="form.list.brand" :hideDisabled="true"></brand-preset>
          </b-col>
          <b-col cols="12" lg="6">
            <category-preset class="" v-model="form.list.category"></category-preset>
          </b-col>
        </b-row>

        <div class="fs-12 bold">
          검색 필드
        </div>
        <form-fields ref="fields" v-model="form.list.fields" :name="$options.name" :customFormFields.sync="customFormFields"
                     v-bind="{formFields, defaultFields}" @enter="list()"></form-fields>

        <b-row class="mb-3">
          <b-col cols="12" md="6" xl="3">
            <div class="clearfix">
              <small>마스터 ID</small>
            </div>
            <div class="flex-row flex-wrap d-flex">
              <b-form-textarea class="flex-grow-0 w-50" :rows="2" v-model="form.list.id_include" placeholder="포함할 마스터 ID"></b-form-textarea>
              <b-form-textarea class="flex-grow-0 w-50" :rows="2" v-model="form.list.id_exclude" placeholder="제외할 마스터 ID"></b-form-textarea>
            </div>
            <b-checkbox size="sm" class="fs-12" v-model="form.list.sortById">입력순 정렬</b-checkbox>
          </b-col>
          <b-col cols="12" md="6" xl="3">
            <small>디자이너 SKU</small>
            <div class="flex-row flex-wrap d-flex">
              <b-form-textarea class="flex-grow-0 w-50" :rows="2" v-model="form.list.designer_sku_include" placeholder="포함할 디자이너 SKU"></b-form-textarea>
              <b-form-textarea class="flex-grow-0 w-50" :rows="2" v-model="form.list.designer_sku_exclude" placeholder="제외할 디자이너 SKU"></b-form-textarea>
            </div>
            <b-checkbox size="sm" class="fs-12" v-model="form.list.sortBySku">입력순 정렬</b-checkbox>
          </b-col>
        </b-row>
      </b-collapse>

      <div class="mt-2">
        <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">
          검색
          <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
        <b-button class="mr-1" variant="warning" @click="resetForm">초기화</b-button>
        <b-button class="mr-1" variant="outline-primary" @click="modal.downIds = true">검색결과 ID Down</b-button>
        <b-button class="" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>
      </div>
    </b-card>

    <master-modal ref="masterModal" v-bind="{modal, shopMap, designerSkuPatternMap}" @save="onSaveModal" @updateField="onUpdateField"></master-modal>

    <image-modal ref="imageModal" v-bind="{modal}"></image-modal>

    <b-modal title="등록상태 일괄변경" size="lg" v-model="modal.processingStatus" @ok="setProcessingStatus">
      <b-alert show variant="info">총 {{ items.list.filter(e => e.selected).length }} 건의 마스터의 등록상태를 변경합니다</b-alert>

      <label>등록상태(processing_status)</label>
      <b-form-radio-group class="col-form-label" v-model="processingStatus" :options="[
        {text: '검수 전', value: 'processing'},
        {text: '검수 후', value: 'registered'},
        {text: '삭제', value: 'terminated'},
      ]"></b-form-radio-group>
      <br/>
      <label>변경사유<span class="text-danger">*</span></label>
      <b-input autocomplete="off" v-model="processingStatusReason"></b-input>

      <template v-if="processingStatus === 'terminated'">
        <b-alert class="mt-2" show variant="danger">선택한 마스터들이 삭제됩니다. 조심 또 조심!</b-alert>
      </template>

    </b-modal>

    <b-modal title="노출상태 일괄변경" size="lg" v-model="modal.displayStatus" @ok="setDisplayStatus">
      <b-alert show variant="info">총 {{ items.list.filter(e => e.selected).length }} 건의 마스터의 노출상태를 변경합니다</b-alert>

      <label>노출상태(display_status)</label>
      <b-form-radio-group class="col-form-label" v-model="displayStatus" :options="[
        {text: '노출', value: 'view'},
        {text: '미노출', value: 'notview'}
      ]"></b-form-radio-group>
      <br/>
      <label>변경사유<span class="text-danger">*</span></label>
      <b-input autocomplete="off" v-model="displayStatusReason"></b-input>
    </b-modal>

    <b-modal title="마스터 통합" size="lg" v-model="modal.mergeMaster" @ok="mergeMaster">
      <b-alert show variant="info">
        2개의 마스터를 통합합니다.<br/>
        통합되는 마스터에 매칭되어 있는 상품들은 매칭 상태를 유지한 상태로 통합하는 마스터에 매칭됩니다.
      </b-alert>
      <label>통합하는 마스터의 ID(이 마스터만 남습니다.)<span class="text-danger">*</span></label>
      <b-input autocomplete="off" v-model="merge.target"></b-input>
      <label>통합되는 마스터의 ID(이 마스터는 사라집니다.)<span class="text-danger">*</span></label>
      <b-input autocomplete="off" v-model="merge.source"></b-input>
    </b-modal>

    <b-modal title="전체 검색결과 ID 다운로드" size="lg" v-model="modal.downIds" :no-close-on-backdrop="busy.downIds" :no-close-on-esc="busy.downIds" hide-header-close>
      <b-alert show variant="info">현재의 검색조건에 대해 마스터의 ID 를 다운로드 합니다.</b-alert>
      <b-alert show variant="warning">다운로드는 200 만개 까지 가능하며, 다운로드에는 10 만개 기준 20 여초 소요됩니다.</b-alert>
      <b-alert show variant="warning">다운로드 중에는 모달을 닫을 수 없습니다.</b-alert>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="downIds()" :disabled="busy.downIds">
          다운로드
          <b-spinner class="ml-1" small v-if="busy.downIds"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()" :disabled="busy.downIds">
          닫기
          <b-spinner class="ml-1" small v-if="busy.downIds"></b-spinner>
        </b-button>
      </template>
    </b-modal>

    <div ref="toolbar" class="clearfix">
      <div v-if="$R('SKU_INHOUSE')" class="pull-left">
        <b-button class="m-1" variant="success" @click="showModal()">마스터 생성</b-button>
        <b-button class="m-1" variant="success" @click="copyMaster()">마스터 복사 생성</b-button>
        <b-button class="m-1" variant="info" @click="showProcessingStatusModal">등록상태 일괄 변경</b-button>
        <b-button class="m-1" variant="info" @click="showDisplayStatusModal">노출상태 일괄 변경</b-button>
        <b-button class="m-1" variant="dark" @click="showMergeMasterModal">마스터 통합</b-button>
      </div>
      <div class="pull-right">

        <b-dropdown right variant="light" class="m-1">
          <template #button-content>
            <i class="fa fa-copy"></i>
          </template>
          <b-dropdown-item @click="copy('id')">마스터 ID</b-dropdown-item>
          <b-dropdown-item @click="copy('designer_sku')">디자이너 SKU</b-dropdown-item>
          <b-dropdown-item @click="copy('designer_sku', {withQuotes: true})">디자이너 SKU(따옴표)</b-dropdown-item>
        </b-dropdown>
        <b-dropdown variant="success" class="m-1" :disabled="busy.xlsxDown">
          <template v-slot:button-content>
            <b-spinner class="mr-2" small v-if="busy.xlsxDown"></b-spinner>
            Xlsx Down
          </template>
          <b-dropdown-item @click="downXlsx('global-upload')">글로벌 정보 업로드 형식</b-dropdown-item>
<!--          <b-dropdown-item @click="downXlsx('master-upload')">마스터 신규 생성 업로드 형식</b-dropdown-item>-->
        </b-dropdown>
        <b-dropdown variant="outline-success" class="m-1" :disabled="busy.xlsxUp">
          <template v-slot:button-content>
            <b-spinner class="mr-2" small v-if="busy.xlsxUp"></b-spinner>
            Xlsx Upload
          </template>
          <b-dropdown-item @click="upXlsx('global-upload')">글로벌 정보 업로드</b-dropdown-item>
<!--          <b-dropdown-item @click="upXlsx('master-upload')">마스터 신규 생성 업로드</b-dropdown-item>-->
        </b-dropdown>
        <input type="file" ref="xlsx-global-upload" data-type="global-upload" style="display: none" @change="handleXlsx">
        <input type="file" ref="xlsx-master-upload" data-type="master-upload" style="display: none" @change="handleXlsx">
      </div>
    </div>
    <form :action="$api.getHost() + '/master/xlsx'" ref="file_form" method="POST" target="file_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j"/>
    </form>
    <div class="bg-white rounded p-2">
      <div class="clearfix">
        <div class="pull-right">
          <b-button variant="primary"
                    @click="_=>{items.list.filter(e=>(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)).forEach(e=>e.selected = true);recalcPicFilteredCnt();$forceUpdate()}">
            전체선택
          </b-button>
          <b-button variant="warning"
                    @click="_=>{items.list.filter(e=>(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)).forEach(e=>e.selected = false);recalcPicFilteredCnt();$forceUpdate()}">
            선택해제
          </b-button>
        </div>
        <b-form inline>
          <b-button-group>
            <b-button :variant="picGroup === 'ALL' ? 'primary' : 'light'" @click="picGroup = 'ALL'">
              {{ items.list.length }} 개 전체 아이템
            </b-button>
            <b-button :variant="picGroup === 'selected' ? 'success' : 'light'" @click="picGroup = 'selected'">
              {{ items.list.filter(e => e.selected).length }} 개 선택된 아이템
            </b-button>
          </b-button-group>
          <b-input-group class="ml-1">
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="fa fa-filter"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="picFilter"></b-form-input>
            <b-input-group-append v-if="picFilter">
              <b-input-group-text>
                {{ picFilteredCnt }} 개
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class="ml-1">
            <b-input-group-prepend>
              <b-input-group-text>이미지 너비</b-input-group-text>
            </b-input-group-prepend>
            <b-form-input class="w-65px text-center" type="number" v-model.number="picWidth"></b-form-input>
            <b-input-group-append><b-input-group-text>px</b-input-group-text></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=175">175</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=195">195</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=225">225</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=265">265</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=318">318</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=400">400</b-button></b-input-group-append>
          </b-input-group>
          <b-checkbox class="ml-2" v-model="picInfoTop">상단정보</b-checkbox>
          <b-checkbox class="ml-2" v-model="picInfoBottom">하단정보</b-checkbox>
        </b-form>
      </div>
      <drag-select ref="drag" class="flex-row flex-wrap d-flex" attribute="attr" selectorClass="itemToBeSelected" @change="dragSelectItems">
        <div v-for="e of items.list"
             v-if="(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)"
             :key="e.id" :attr="e.id" :selected="e.selected ? 1 : 0"
             class="flex-grow-0 m-1 position-relative itemToBeSelected"
             :style="{width:picWidth+'px', padding:'3px', border:e.selected ? '3px solid #20a8d8' : '3px solid #f8f8f8'}"
             @click="clickItem($event, e)">
          <div class="position-absolute text-right" style="right:0;line-height:15px">
            <div v-if="picInfoTop">
              <b-badge class="pointer mr-1" variant="info" size="sm" @click.prevent.stop="showImageModal(e)">이미지 보기<i class="fa fa-external-link"></i></b-badge>
              <b-badge class="pointer" size="sm" @click.prevent.stop="showModal(e)">상세</b-badge>
              <br/>
              <b-badge variant="success" class="pointer" size="sm" @click.prevent.stop="copyOne(e.id)"><i class="fa fa-copy"></i> {{ e.id }}</b-badge>
              <br/>
              <b-badge v-if="e.processing_status === 'processing'" variant="primary">검수 전</b-badge>
              <b-badge v-else-if="e.processing_status === 'registered'" variant="dark">검수 후</b-badge>
              <b-badge v-else variant="danger">삭제</b-badge>
              <b-badge v-if="e.display_status === 'view'" variant="dark" class="ml-1">노출</b-badge>
              <b-badge v-else variant="danger" class="ml-1">미노출</b-badge>
            </div>
          </div>
          <img :src="((e.studio_img_urls && e.studio_img_urls.length)? e.studio_img_urls : e.img_urls).concat(e.temp_img_urls)[0]" @dragstart.prevent=""
               :data-id="e.id" @mouseover="rotateImage($event, e)" @mouseout="stopRotate($event, e)" class="w-100" style="min-height: 217px; max-height: 217px;"/>
          <!-- 하단 정보 -->
          <div v-if="picInfoBottom" class="info-bottom" style="line-height:18px">
            <span v-html="makeBrandBadge(e.brand_no, e.brand_nm, {type: brandMap[e.brand_no].brand_type})"></span>
<!--            <b-badge variant="warning">{{ e.brand_no }}. {{ e.brand_nm }}</b-badge>-->
            <br/>
            <div class="text-truncate overflow-hidden fs-11 bold" :title="e.name">{{ e.name }}</div>
            <div class="text-truncate overflow-hidden fs-11" :title="e.comments">{{ e.comments }}</div>
            <div class="clearfix mb-1">
              <div class="pull-left text-truncate overflow-hidden">
                <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${e.designer_sku}`" class="badge badge-info"
                   target="_blank">{{ e.designer_sku }} <i class="fa fa-external-link"></i></a>
                <b-badge variant="info" class="pointer" @click.prevent.stop="copyOne(e.designer_sku)"><i class="fa fa-copy"></i></b-badge>
              </div>
            </div>
          </div>
        </div>
      </drag-select>
      <div v-if="hasMore.list" class="text-center py-3">
        <b-button variant="primary" size="lg" @click="list(true)">더 가져오기</b-button>
      </div>
    </div>
    <div @click="scrollTo('toolbar')" class="text-right pointer" style="bottom: 1rem; position: fixed; right: 2rem; width: fit-content; z-index: 10;">
      <b-button pill variant="outline-secondary">Top<i class="ml-1 fa fa-arrow-up"></i></b-button>
    </div>
  </div>
</template>

<script>
import masterModal from '@/views/master/MasterModal.vue'
import imageModal from '@/views/master/ImageModal.vue'
import DragSelect from '@/views/modules/DragSelect.vue'
import xlsx from '@/views/modules/Xlsx.vue'
import {MASTER_COLUMNS} from 'balaan_constants'
import * as utils from "@/shared/utils";
import {readXlsx, down} from "@/shared/impexp";
import * as C from "balaan_constants";
import {postJson} from "@/shared/api";
import Vue from "vue";
import FormFields from "../modules/FormFields";
import FormOptions from "../modules/FormOptions";
import ColorCheckbox from "../modules/ColorCheckbox";
import {formOptionsPreset} from "@/shared/fields";
import ListDataMixin from '../modules/ListDataMixin';
import moment from 'moment-timezone';

const FEATURES_MAX_COUNT = 7;

export default {
  name: 'MasterList',
  title: '마스터SKU 생성/조회',
  mixins: [
    ListDataMixin
  ],
  components: {masterModal, imageModal, DragSelect, FormFields, FormOptions, ColorCheckbox},
  data() {
    return {
      MASTER_COLUMNS,
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      designerSkuPatterns: [],
      designerSkuPatternMap: {},
      defaultForm: {
        list: {
          search: '',
          brand: [],
          category: [],
          processing_status: ['processing', 'registered', 'terminated'],
          manual: 'ALL',

          naverKeyword: 'ALL',
          min_price_all: 'ALL',
          min_price_naver: 'ALL',
          naver_urls: 'ALL',
          min_price_naver_rank: 'ALL',
          min_price_naver_abroad_rank: 'ALL',
          naver_rank_urls: 'ALL',
          naver_rank_abroad_urls: 'ALL',
          min_price_danawa: 'ALL',
          min_price_naver_api: 'ALL',
          min_price_balaan: 'ALL',
          minPriceManual: 'ALL',
          danawa_urls: 'ALL',
          danawa_list_urls: 'ALL',
          rankUrlCFD: 'ALL',
          urlCFD: 'ALL',
          sales_14d: 'ALL',

          goodsType: 'ALL',
          display_status: 'ALL',
          created_type: 'ALL',

          sortKey: 'id',
          sortDir: 'desc',
          limit: 100,
          skip: 0,

          fields: {},

          name_en: 'ALL',
          composition_tag: 'ALL',
          composition: 'ALL',
          features: 'ALL',
          size_table: 'ALL',
          measurements: 'ALL',
          season: 'ALL',
          description: 'ALL',
          final_images: 'ALL',
          temp_images: 'ALL',
          studio_images: 'ALL',

          color: this.$C.COLORS.map(e => e.name),

          id_include: '',
          id_exclude: '',
          designer_sku_include: '',
          designer_sku_exclude: '',
          sortById: true,
          sortBySku: true,
        }
      },
      form: {
        list: {},
      },
      merge: {
        source: '',
        target: '',
      },
      lastBody: {list: {}},
      collapse: {detail: false},
      items: {list: []},
      busy: {list: false, listmore: false, xlsxDown: false, xlsxUp: false, downIds: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      perPage: 20,
      itemMode: 'pic',
      picFilter: '',
      picFilteredCnt: 0,
      picGroup: 'ALL',
      picWidth: 175,
      picInfoTop: true,
      picInfoBottom: true,
      rotateHandler: null,

      modal: {detail: false, displayStatus: false, processingStatus: false, diffList: false, image: false, mergeMaster: false, downIds: false},
      diff: null,
      displayStatus: 'view',
      displayStatusReason: '',
      processingStatus: 'processing',
      processingStatusReason: '',
      xlsx: {
        keys: [],
        labels: [],
      },
      xlsxItems: [],
      formOptions: [
        [
          {
            name: '등록상태', type: 'checkbox', key: 'processing_status', options: [
              {text: '검수 전', value: 'processing'},
              {text: '검수 후', value: 'registered'},
              {text: '삭제', value: 'terminated'}
            ]
          },
          {
            name: '노출상태', key: 'display_status', options: [
              {text: '전체', value: 'ALL'},
              {text: '노출', value: 'view', variant: 'success'},
              {text: '미노출', value: 'notview', variant: 'warning'}
            ]
          },
          {
            name: '생성유형', key: 'created_type', options: [
              {text: '전체', value: 'ALL'},
              {text: '자동', value: 'auto', variant: 'info'},
              {text: '수동', value: 'manual', variant: 'success'},
              {text: '수동 일괄', value: 'excel-upload', variant: 'success'}
            ]
          },
          {key: 'divider'},
          {name: '발란추천가', key: 'min_price_all', options: formOptionsPreset.EXISTS_YN},
          {name: 'B최저가', key: 'min_price_balaan', options: formOptionsPreset.EXISTS_YN},
          {name: 'N최저가', key: 'min_price_naver', options: formOptionsPreset.EXISTS_YN},
          {name: 'N카탈로그 URL', key: 'naver_urls', options: formOptionsPreset.EXISTS_YN},
          {name: 'N랭킹 국내 최저가', key: 'min_price_naver_rank', options: formOptionsPreset.EXISTS_YN},
          {name: 'N랭킹 해외 최저가', key: 'min_price_naver_abroad_rank', options: formOptionsPreset.EXISTS_YN},
          {name: 'N랭킹 국내 카탈로그 URL', key: 'naver_rank_urls', options: formOptionsPreset.EXISTS_YN},
          {name: 'N랭킹 해외 카탈로그 URL', key: 'naver_rank_abroad_urls', options: formOptionsPreset.EXISTS_YN},
          {name: 'D최저가', key: 'min_price_danawa', options: formOptionsPreset.EXISTS_YN},
          {name: 'D검색 URL', key: 'danawa_urls', options: formOptionsPreset.EXISTS_YN},
          {name: 'D통합검색 URL', key: 'danawa_list_urls', options: formOptionsPreset.EXISTS_YN},
          {
            name: 'N랭킹 검수일', key: 'rankUrlCFD', options: [
              {text: '전체', value: 'ALL'},
              {text: '없음', value: 'null', variant: 'warning'},
              {text: '오늘', value: 'today', variant: 'success'},
              {text: '오늘이전', value: 'past', variant: 'secondary'},
            ]
          },
          {
            name: '최저가 검수일', key: 'urlCFD', options: [
              {text: '전체', value: 'ALL'},
              {text: '없음', value: 'null', variant: 'warning'},
              {text: '오늘', value: 'today', variant: 'success'},
              {text: '오늘이전', value: 'past', variant: 'secondary'},
            ]
          },
          {name: '14일 판매이력', key: 'sales_14d', options: formOptionsPreset.EXISTS_Y},
          {name: 'API 키워드', key: 'naverKeyword', options: formOptionsPreset.EXISTS_YN},
          {name: 'API 최저가', key: 'min_price_naver_api', options: formOptionsPreset.EXISTS_YN},
          {name: '수동 최저가', key: 'minPriceManual', options: formOptionsPreset.EXISTS_YN},
        ]
      ],
      formOptionsDetail: [
        [
          // {key: 'divider'},
          {name: '상품명 (영문)', key: 'name_en', options: formOptionsPreset.EXISTS_YN},
          {name: '소재 태그', key: 'composition_tag', options: formOptionsPreset.EXISTS_YN},
          {name: '소재', key: 'composition', options: formOptionsPreset.EXISTS_YN},
          {name: '상품 특징', key: 'features', options: formOptionsPreset.EXISTS_YN},
          {name: '사이즈 변환', key: 'size_table', options: formOptionsPreset.EXISTS_YN},
          {name: '실측 사이즈', key: 'measurements', options: formOptionsPreset.EXISTS_YN},
          {name: '시즌', key: 'season', options: formOptionsPreset.EXISTS_YN},
          {name: '상세 정보', key: 'description', options: formOptionsPreset.EXISTS_YN},
          {key: 'divider'},
          {name: '임시 이미지', key: 'temp_images', options: formOptionsPreset.EXISTS_YN},
          {name: '최종 이미지', key: 'final_images', options: formOptionsPreset.EXISTS_YN},
          {name: '촬영 이미지', key: 'studio_images', options: formOptionsPreset.EXISTS_YN},
        ],
        [
          {key: 'divider'},
          {
            name: '정렬기준', key: 'sortKey', options: [
              {text: '마스터ID', value: 'id', variant: 'primary'},
              {text: '디자이너SKU', value: 'designer_sku', variant: 'info'},
              {text: '수정시각', value: '_mdt', variant: 'info'},
              {text: '이미지업로드시각', value: 'img_uploaded_dt', variant: 'info'},
              {text: '등록시각', value: 'registered_dt', variant: 'info'},
              {text: '노출시각', value: 'displayed_dt', variant: 'info'},
            ]
          },
          {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR},
        ],
      ],
      formFields: [
        {name: '시즌', key: 'season', type: 'string', placeholder: '23FW'},
        {name: '생성시각', key: '_cdt', type: 'string', placeholder: '2020-01-01 01:02:03', disableLike: true, width: 155},
        {name: '등록시각', key: 'registered_dt', type: 'string', placeholder: '2020-01-01 01:02:03', disableLike: true, width: 155},
        {name: '수정시각', key: '_mdt', type: 'string', placeholder: '2020-01-01 01:02:03', disableLike: true, width: 155},
      ],
      defaultFields: '_cdt:range,registered_dt:range,_mdt:range',
      customFormFields: [],
      validator: {
        'global-upload': {
          'GM ID': {test: /^GM\d{6,8}$/, type: 'string', required: true},
        },
      }
    }
  },
  sockets: {
    master_list_query(q) {
      console.log(q);
    }
  },
  async created() {
    // form 의 구조가 복잡해져서 최초 초기화와 reset 을 분리
    Vue.set(this.form, 'list', this.$utils.clone(this.defaultForm.list));

    this.$utils.getStatus(this.$options.name, this, 'collapse,itemMode,picWidth,perPage');

    let meta = await this.$api.getMeta('shop,brand,category,designer_sku_pattern');
    // 디자이너 SKU Pattern 가져오기
    this.designerSkuPatterns = meta.designer_sku_pattern.map(e => {
      return this.designerSkuPatternMap[e.brand_no] = e;
    });

    meta.shop.forEach(s => {
      s.value = s.boutique;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    }); // use_yn 무관 일단 정보는 필요
    this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);

    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));

    if (Object.keys(this.$route.query).length) {
      Object.keys(this.$route.query).forEach(k => {
        if (this.$route.query[k] != null) {
          let v = this.$route.query[k];
          if (~'limit,skip'.split(',').indexOf(k)) {
            this.form.list[k] = +v;
          } else if (k === '$category') {
            let arr = v.split(',');
            this.form.list.category = arr.map(e => this.categoryMap[e]);
          } else if (k === 'collapse.detail') {
            this.collapse.detail = !(!v || v === 'false');
          } else if (k === 'itemMode') {
            this.itemMode = v;
          } else if (~Object.keys(this.form.list).indexOf(k)) {
            this.form.list[k] = v;
          }
        }
      });
    }

    const id = this.$route.params.id;
    if (id) {
      this.form.list.search = id;
    }
    this.list();
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e => e).forEach(e => e.abort());
    this.$utils.setStatus(this.$options.name, this, 'collapse,itemMode,picWidth,perPage');
  },
  watch: {
    picFilter(v) {
      if (v) {
        this.items.list.forEach(e => {
          let uv = v.toUpperCase();
          e.filtered = e.name.toUpperCase().includes(uv)
            || e.id.includes(uv)
            || e.designer_sku.toUpperCase().includes(uv)
            || (e.brand_nm || '').toUpperCase().includes(uv)
            || (e.category_nm || '').toUpperCase().includes(uv)
        });
        this.picFilteredCnt = this.items.list.filter(e => e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
      }
    },
    picGroup(v) {
      this.picFilteredCnt = this.items.list.filter(e => e.filtered && (v === 'ALL' || e.selected)).length;
    },
    itemMode() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    picWidth() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    perPage() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
  },
  methods: {
    rotateImage($event, e) {
      let i = 0;
      const $img = $event.srcElement;
      const img_urls = ((e.studio_img_urls && e.studio_img_urls.length) ? e.studio_img_urls.concat(e.img_urls) : e.img_urls).concat(e.temp_img_urls);
      this.rotateHandler = setInterval(() => {
        i = (i + 1) % img_urls.length;
        $img.src = img_urls[i];
      }, 500);
    },
    stopRotate($event, e) {
      clearInterval(this.rotateHandler);
      const img_urls = ((e.studio_img_urls && e.studio_img_urls.length) ? e.studio_img_urls.concat(e.img_urls) : e.img_urls).concat(e.temp_img_urls);
      $event.srcElement.src = img_urls[0];
    },
    dragSelectItems(divs) {
      const selectedMap = {};
      this.items.list.forEach(e => selectedMap[e.id] = e.selected);
      divs.map(e => e.getAttribute('attr')).forEach(e => selectedMap[e] = true);
      this.items.list.forEach(e => e.selected = !!selectedMap[e.id]);

      this.$forceUpdate();
    },
    calcIncExc(inc, exc) {
      let include = inc ? inc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      let exclude = exc ? exc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      if (include.length && exclude.length) { // 둘 다 존재시 exclude 를 include 에서 제외
        const excludeMap = this.$utils.arr2map(exclude);
        include = include.filter(e => !excludeMap[e]);
        exclude = [];
      }
      return [include, exclude];
    },
    makeListFormBody() {
      const form = this.form.list;
      const pattern = /^[\s|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9]*$/gi;
      if (!pattern.test(form.search)) return alert('검색어에는 특수문자가 들어갈 수 없습니다.');

      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];

      const [id_include, id_exclude] = this.calcIncExc(form.id_include, form.id_exclude);
      const [designer_sku_include, designer_sku_exclude] = this.calcIncExc(form.designer_sku_include, form.designer_sku_exclude);
      const body = {
        ...form,
        brand,
        category,
        fields,
        id_include,
        id_exclude,
        designer_sku_include,
        designer_sku_exclude
      }

      return body;
    },
    async list(more) {
      const body = this.makeListFormBody();

      const j = await this.$api.postTable(this, '/master/list', body, {more});
      if (this.form.list.sortById && body.id_include.length) {
        const sortMap = this.$utils.arr2map(body.id_include.map((e, i) => ({e, i})), 'e', 'i');
        this.items.list.forEach(e => e._sort = sortMap[e.id]);
        this.items.list.keySort('_sort');
      }
      if (this.form.list.sortBySku && body.designer_sku_include.length) {
        const sortMap = this.$utils.arr2map(body.designer_sku_include.map((e, i) => ({e, i})), 'e', 'i');
        this.items.list.forEach(e => e._sort = sortMap[e.designer_sku]);
        this.items.list.keySort('_sort');
      }

      // 브랜드속성을 추가해준다.
      // https://www.notion.so/4198820b7eea40a09c6728a155e7d423?pvs=4#4cb38f7ed4114026a5aa8605e1ee034f
      this.items.list.forEach(e => {
        e.brand_type_kr = (this.$C.BRAND_TYPE_MAP[this.brandMap[e.brand_no].brand_type] || {}).name || '';
      });
      // 검색결과중 검색필드와 일치하는 데이터가 있다면 url 을 바꿔준다.
      // if (j && j.list.filter(e=>e.id + '' === form.search).length && location.hash === '#/master/list') {
      //   history.replaceState(null, null, location.origin + '/#/master/list/' + form.search);
      // }
    },
    async downIds() {
      const body = this.makeListFormBody();
      body.returnIdField = 'id';
      this.busy.downIds = true;
      const j = await this.$api.postJson('/master/list', body);
      if (j) {
        down(j.list, ['id'], ['id'], `GmIds_${this.$utils.dt()}`, 'txt');
        //
        // this.$refs.json_data.value = JSON.stringify({
        //   data: j.data.map(e => ({id: e})),
        //   keys: ['id'],
        //   labels: [body.returnIdField],
        //   type: 'txt',
        //   name: `ConfirmedIds_${this.$utils.dt()}.txt`
        // });
        // this.$refs.xlsx_form.submit();
      }
      this.busy.downIds = false;
    },

    onSaveModal(e) {
      this.list();
    },
    onUpdateField(id, field, value) {
      const gm = this.items.list.find(e => e.id === id);
      if (gm) gm[field] = value;
    },

    showModal(item) {
      this.$refs.masterModal.showModal(item);
    },

    copyMaster(e) {
      const selectedItems = this.items.list.filter(e => e.selected);
      if (!selectedItems.length) {
        this.$alertTop('복사할 마스터를 선택해주세요', {variants: 'danger', timeout: 30000});
        return;
      }

      if (selectedItems.length > 1) {
        this.$alertTop('복사할 마스터를 하나만 선택해주세요', {variants: 'danger', timeout: 30000});
        return;
      }
      const [{category, brand_no}] = selectedItems;
      this.$refs.masterModal.showModal({category, brand_no});
    },

    showImageModal(item) {
      this.$refs.imageModal.show(item);
    },

    showProcessingStatusModal() {
      const selectedItems = this.items.list.filter(e => e.selected);
      if (!selectedItems.length) {
        this.$alertTop('등록상태를 변경할 마스터를 선택해주세요', {variants: 'danger', timeout: 30000});
        return;
      }

      this.processingStatusReason = '';
      this.modal.processingStatus = true;
    },

    showMergeMasterModal(e) {
      this.merge.source = '';
      this.merge.target = '';
      this.modal.mergeMaster = true;
    },

    async mergeMaster(e) {
      if (!this.merge.target || !this.merge.source) {
        alert('통합하는 마스터의 ID와 통합되는 마스터의 ID를 모두 입력해 주세요.');
        e.preventDefault && e.preventDefault();
        return;
      }

      let j = await this.$api.postJson('/master/mergeMaster', {
        target: this.merge.target,
        source: this.merge.source,
      });
      if (j) {
        this.$alertTop('마스터 통합이 완료되었습니다', {timeout: 3000});
        this.list();
      }
    },

    async setProcessingStatus(e) {
      if (!this.processingStatusReason) {
        alert('변경사유를 입력해주세요');
        e.preventDefault && e.preventDefault();
        return;
      }
      const ids = this.items.list.filter(e => e.selected).map(e => e.id);
      let j = await this.$api.postJson('/master/setProcessingStatus', {
        form: {
          ids,
          processing_status: this.processingStatus,
          reason: this.processingStatusReason,
        }
      });
      if (j) {
        this.$alertTop('적용되었습니다');
        // 검수 트래킹
        if (this.processingStatus === 'registered') {
          this.$api.sendBeacon('/master/track/registerMaster', {
            gm_ids: ids,
          });
        }
        this.list();
      }
    },

    showDisplayStatusModal() {
      const selectedItems = this.items.list.filter(e => e.selected);
      if (!selectedItems.length) {
        this.$alertTop('노출상태를 변경할 마스터를 선택해주세요', {variants: 'danger', timeout: 30000});
        return;
      }
      const processingItems = selectedItems.filter(e => e.processing_status === 'processing');
      if (processingItems.length) {
        this.$alertTop('검수 전 마스터의 노출상태는 변경이 불가합니다', {variants: 'danger', timeout: 30000});
        return;
      }
      const terminatedItems = selectedItems.filter(e => e.processing_status === 'terminated');
      if (terminatedItems.length) {
        this.$alertTop('삭제된 마스터의 노출상태는 변경이 불가합니다', {variants: 'danger', timeout: 30000});
        return;
      }

      this.displayStatusReason = '';
      this.modal.displayStatus = true;
    },

    async setDisplayStatus(e) {
      if (!this.displayStatusReason) {
        alert('변경사유를 입력해주세요');
        e.preventDefault && e.preventDefault();
        return;
      }
      let j = await this.$api.postJson('/master/setDisplayStatus', {
        form: {
          ids: this.items.list.filter(e => e.selected).map(e => e.id),
          display_status: this.displayStatus,
          reason: this.displayStatusReason,
        }
      });
      if (j) {
        this.$alertTop('적용되었습니다');
        this.list();
      }
    },

    clickItem(e, item) {
      item.selected = !item.selected;
      this.$forceUpdate();
    },

    recalcPicFilteredCnt() {
      this.picFilteredCnt = this.items.list.filter(e => e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
    },

    copy(col, {withQuotes = false} = {}) {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('복사할 상품을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.map(e => withQuotes ? `'${e[col].toString().replace(/'/g, "\\'")}'` : e[col]).join(withQuotes ? ',\n' : '\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },

    copyOne(content) {
      if (this.$utils.copyToClipboard(content)) this.$alertTop(`복사되었습니다`);
    },

    scrollTo(ref) {
      const y = this.$refs[ref].offsetTop - this.$refs[ref].clientHeight - 20;
      window.scroll({
        top: y,
        left: 0,
        behavior: 'smooth',
      })
    },
    preDown(type) {
      if (!this.$R('BALAANEER')) return this.downPartner(type);
      this.xlsxItems = this.items.list.filter(e => e.selected).map(e => this.$utils.clone(e));
      if (!this.xlsxItems.length) return alert('다운받을 상품을 선택해주세요');
      let baseFields = {};
      Object.entries(MASTER_COLUMNS).forEach(([k, v]) => {
        if (v.use !== false) baseFields[k] = v.name;
      });

      let fields = Object.keys(baseFields).join(',');
      let headers = Object.values(baseFields).join(',');

      for (const si of this.xlsxItems) {

        const categoryPath = si.category_path.split('>').map(e => e.trim());
        si.gender = categoryPath[0] || '';
        si.first = categoryPath[1] || '';
        si.second = categoryPath[2] || '';
        si.third = categoryPath[3] || '';

        si.has_ep_image = !!si.ep_img_url;
      }

      this.xlsx.keys = fields.split(',');
      this.xlsx.labels = headers.split(',');
      return true;
    },
    async downXlsx(type) {
      if(type === 'global-upload') {
        if (!this.items.list.filter(e => e.selected).length) {
          return alert('다운받을 상품을 선택해주세요');
        }

        const headers = ['마스터 ID', '디자이너 SKU(Full)', '상품명(한글)', '상품명(영문)', '브랜드 번호', '브랜드명', '카테고리', '카테고리명', '색상', '소재 태그', '소재(영문)', '상품 특징 1(영문)', '상품 특징 2(영문)', '상품 특징 3(영문)', '상품 특징 4(영문)', '상품 특징 5(영문)', '상품 특징 6(영문)', '상품 특징 7(영문)', '상품 설명(영문)',]
        const selectedItems = this.items.list.filter(e => e.selected);
        const excelData = [];

        selectedItems.map(item => {
          item.features_en = Array.isArray(item.features_en)
              ? Array.from({ length: FEATURES_MAX_COUNT }, (_, i) => item.features_en[i] || '')
              : Array(FEATURES_MAX_COUNT).fill('');

          excelData.push([item.id,
                  item.designer_sku,
                  item.name,
                  item.name_en || '',
                  item.brand_no || '',
                  item.brand_nm || '',
                  '' + (item.category || ''),
                  item.category_nm || '',
                  item.color || '',
                  item.composition_tag || '',
                  item.composition || '',
                  item.features_en[0] || '',
                  item.features_en[1] || '',
                  item.features_en[2] || '',
                  item.features_en[3] || '',
                  item.features_en[4] || '',
                  item.features_en[5] || '',
                  item.features_en[6] || '',
                  item.description_en || ''
              ]);
        });
        down(excelData, headers, null, `글로벌업로드양식-${moment().format("YYYY-MM-DD")}`, 'xlsx');
      } else {
        const downloadFileName = 'GM마스터업로드양식.xlsx';
        await this.$api.downloadFile('/master/download/xlsx', { fileName: 'master' }, downloadFileName);
      }
    },
    upXlsx(type){
      this.$refs['xlsx-' + type].value = null;
      this.$refs['xlsx-' + type].click();
    },
    async handleXlsx(event) {
      const file = (event.dataTransfer || event.target).files[0];

      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) {
        return utils.alert('xlsx 파일을 업로드해주세요');
      }

      const {headers, rows} = await readXlsx(file);
      const type = event.target.dataset.type;

      if (type === 'global-upload') {
        this.uploadGlobalXlsx(event.target, headers, rows);
      } else {
        this.uploadMasterXlsx(event.target, headers, rows);
      }
    },

    async uploadGlobalXlsx(target, headers, rows) {
      this.busy.xlsxUp = true;
      const j = await this.$api.postJson('/master/uploadGlobal', {rows});
      this.busy.xlsxUp = false;
      if (j.ok === 1) {
        this.$utils.alert(`${j.cnt} 건 정상적으로 업로드 되었습니다`);
        this.list();
      } else if (j.ok === -1) {
        this.$modal.show({title: '업로드 에러 확인', html: '<pre>' + `<h4>${j.msg}</h4>` + '</pre>'});
      }
      target.value = '';
    },
    async uploadMasterXlsx(target, header, rows) {
      const response = await this.$api.postJson('/master/uploadMaster', {rows});
      if(response.ok === 1) {
        let resultMessage = `${response.successCount} 개 마스터 생성이 완료 되었습니다.`;
        if(response.existingSkus.length > 0) {
          resultMessage += `<br/>미등록된 ${response.existingSkus.length} 개는 이미 동일한 디자이너 SKU를 가진 마스터가 있습니다.`;
          resultMessage += '<textarea rows="3" class="w-100">' + response.existingSkus.join('\n') + '</textarea>';
        }
        this.$utils.alert(resultMessage);
        this.list();
      }
    },
    resetForm() {
      const fields = this.form.list.fields;
      this.form.list = this.$utils.clone(this.defaultForm.list);
      Vue.set(this.form.list, 'fields', fields);
      this.$refs.fields.resetFieldValues();
    },
  }
}
</script>
